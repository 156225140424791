import React from "react";
import { Box } from "@mui/material";
import ControlButton from "./ControlButton";
import LiveStatusButton from "./LiveStatusButton";
import NavigationIcon from "./NavigationIcon";

const LiveStream = ({ liveUrl, openFullscreen, openMic, openRecord, openSound }) => {
  const handleIconClick = (stateKey, newState) => {
    console.log(`Icon ${stateKey} is now ${newState ? "on" : "off"}`);
    // Add additional logic here

    const handlerMap = {
      record: openRecord,
      sound: openSound,
      mic: openMic,
      shield: () => console.log("Shield toggled"),
      dim: () => console.log("Dim toggled"),
      horn: () => console.log("Horn toggled"),
      zoom: openFullscreen, // Special case for non-toggleable Zoom
    };

    // Call the appropriate handler, if defined
    if (handlerMap[stateKey]) {
      handlerMap[stateKey](newState);
    } 
  };
  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "#000",
        height: "100vh",
        width: "100%",
      }}
    >
      <LiveStatusButton variant="outlined" color="green" text="Live" />
      <NavigationIcon 
        position={{ top: 10, right: 10 }} 
        onIconClick={handleIconClick}
        iconsToShow={['zoom', 'sound', 'record','mic', 'dim','horn','shield']}
      />
      <img
        src={liveUrl}
        alt="Live"
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
        }}
      />
      {/* TODO take out this code cz still out of scope PTZ button  */}
      {/* <Box
        sx={{
          position: "absolute",
          bottom: 10,
          right: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <ControlButton direction="up" onClick={() => console.log("Up")} />
        <Box sx={{ display: "flex" }}>
          <ControlButton direction="left" onClick={() => console.log("Left")} />
          <ControlButton direction="down" onClick={() => console.log("Down")} />
          <ControlButton
            direction="right"
            onClick={() => console.log("Right")}
          />
        </Box>
      </Box> */}
      {/* TODO take out this code cz still out of scope PTZ button  */}
    </Box>
  );
};

export default LiveStream;
