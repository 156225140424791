import React from "react";
import Svg from "../../common/Svg";
import CustomDropdown from "./components/CustomDropDown";
import SearchBar from "./components/SearchBar";
import AddDeviceButton from "./components/AddDeviceBtn";
import { getLocal, checkUserRoutePermission } from "../../../helper/common";

export default function DisplayControls({
  route,
  activeColumn,
  handleResponsiveColumns,
  defaultView,
  setDefaultView,
  defaultViewApi,
  LIST_VIEW,
  SITE_VIEW,
  onSearch,
  addDeviceRoute,
  showListView = true,
  showDropdown = true,
  showSearchBar = true,
  showAddDeviceButton = true,
}) {
  let role = getLocal("role");
  
  return (
    <div className="display-wrap d-flex align-items-center justify-content-between w-100">
      {/* Left Section: Display Controls */}
      <div className="d-flex flex-grow-1">
        <div className="d-flex align-items-center justify-content-center me-3">
          <div className="title mr-2">Display</div>
          <div className="display-inputs d-flex align-items-center">
            {[1, 2, 3, 4].map((col) => (
              <div
                key={col}
                className={`icon-checkbox mr-1 ${
                  activeColumn === col ? "active" : ""
                }`}
                onClick={() => handleResponsiveColumns(col)}
              >
                <Svg name={`grid${col}`} />
              </div>
            ))}
          </div>
        </div>
        {showListView && (
        <div className="d-flex align-items-center justify-content-center list-view-site-view custom-list-box mr-2">
          <button
            className={defaultView === LIST_VIEW ? "active list-btn" : "list-btn"}
            onClick={() => {
              setDefaultView(LIST_VIEW);
              defaultViewApi(LIST_VIEW);
            }}
          >
            List View
          </button>
          <button
            className={defaultView === SITE_VIEW ? "active list-btn" : "list-btn"}
            onClick={() => {
              setDefaultView(SITE_VIEW);
              defaultViewApi(SITE_VIEW);
            }}
          >
            Site View
          </button>
        </div>
        )}
         {showDropdown && (
        <div className="dropdown">
          <CustomDropdown />
        </div>
        )}
      </div>
      {/* Right Section: SearchBar and AddDeviceButton */}
      <div className="d-flex align-items-center">
      {showSearchBar && <SearchBar onSearch={onSearch} className="mr-2" />}
      {showAddDeviceButton &&
          checkUserRoutePermission(role, `/${route}/cameras/add-new-device`) && (
            <AddDeviceButton to={addDeviceRoute} />
          )}
      </div>
    </div>
  );
}