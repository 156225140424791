export const toUpperCaseText = (text) => {
    return text.toUpperCase();
}

export const MAC_DEFAULT = "123456789123"

export const getAuthHeaders = () => {
    return {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
  };

export const truncateString = (str, length) => {
    if (str.length > length) {
        return str.substring(0, length) + "...";
    }
    return str;
}

export const transformColumn = (val) => {
  switch (val) {
    case 1:
      return 2;
    case 2:
      return 4;
    case 3:
      return 9;
    case 4:
      return 12;
    default:
      return null;
  }
}

export const formatBadgeContent = (value) => {
  return value > 9 ? '9+' : value.toString();
}