import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import HeaderDashboard from "../../common/HeaderDashboard";
import GroupHeaders from "./GroupHeader";
import { getLocal } from "../../../helper/common";
import { useUiContext } from "../../../contexts/UiContext";
import DisplayControls from "./DisplayControl";
import CustomPagination from "./components/CustomPagination";
import CameraGrid from "./components/CameraGrid";
import SiteView from "../siteview/SiteView";
import { useAddressList } from "./services/useAddressList";
import { useToken } from "./services/useToken";
import { useDeviceList } from "./services/useDeviceList";
import { useGroupList } from "./services/useGroupList";
import { transformColumn } from "../../../helper/utils";

export default function CameraList({ route }) {
  const {
    defaultView,
    setDefaultView,
    searchFilter,
    setSearchFilter,
    sortBy,
    setSortBy,
    noOfColumns,
    setNoOfColumns,
    setNoOfColumnsGroupCameras,
  } = useUiContext();
  let role = getLocal("role");
  const [groupUuid, setGroupUuid] = useState("");
  const [activeGroupName, setActiveGroupName] = useState("All");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [addressesLatLog, setAddressesLatLog] = useState([]);
  const [addressesNames, setAddressesNames] = useState([]);
  const LIST_VIEW = "LIST_VIEW";
  const params = useLocation();
  const { fetchAddressList } = useAddressList();
  const { authToken, setAuthToken, getTokens } = useToken();
  const { deviceList, totalPageNo, currentPage, getDeviceList, getDeviceListManual, setCurrentPage } =
    useDeviceList();
  const { groups, fetchGroups } = useGroupList();

  useEffect(() => {
    const savedGroupUuid = localStorage.getItem("groupUuid");
    if (savedGroupUuid) {
      setGroupUuid(savedGroupUuid);
    }
  }, []);

  const getAddressList = async () => {
    const { latlogArray, siteAddresses } = await fetchAddressList();
    setAddressesLatLog(latlogArray);
    setAddressesNames(siteAddresses);
  };

  useEffect(() => {
    if (role === "customer" || role === "customerIsAdmin") {
      fetchGroups();
      getAddressList();
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("groupUuid", groupUuid);
  }, [groupUuid]);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const params = {
          page,
          ...(groupUuid && { group_uuid: groupUuid }),
          ...(searchFilter && { search: searchFilter }),
          ...(sortBy && { ordering: sortBy }),
          ...(limit && { limit: limit }),
        };
        console.log("Dependencies updated:", {
          page,
          groupUuid,
          searchFilter,
          sortBy,
        }); // #TODO - DEL
        const token = await getTokens();
        setAuthToken(token);
        if (role === "customer" || role === "customerIsAdmin") {
          // TODO change to server side only after BE provide server side function
          if (params.page === 1) {
            await getDeviceList(params, groupUuid);
          } else {
            await getDeviceListManual(params);
          }
        }
      } catch (error) {
        console.error("Error fetching devices:", error);
      }
    };

    fetchDevices();
  }, [groupUuid, searchFilter, sortBy, page, limit]);

  const handlePageChange = (value) => {
    setCurrentPage(value);
    setPage(value)
  };

  const defaultViewApi = (view) => {
    console.log(`Default view set to: ${view}`);
  };

  const handleResponsiveColumns = (columns) => {
    const total = transformColumn(columns)
    setLimit(total)
    if (params.pathname.includes("/group/details")) {
      setNoOfColumnsGroupCameras(columns);
    }
    if (params.pathname.includes("/camera")) {
      setNoOfColumns(columns);
    }
  };

  const typingTimerRef = useRef(null);
  const handleSearch = (value) => {
    console.log("Search query:", value);
    clearTimeout(typingTimerRef.current);
    typingTimerRef.current = setTimeout(() => setSearchFilter(value), 1000);
  };

  return (
    <div className="d-flex flex-column site-main-wrapper grid-page">
      <HeaderDashboard />
      <div className="flex-grow-1 main-wrapper">
        <div className="main-page-height devices-page d-flex flex-column">
          <GroupHeaders
            groups={groups}
            activeGroupName={activeGroupName}
            groupUuid={groupUuid}
            setGroupUuid={setGroupUuid}
            setActiveGroupName={setActiveGroupName}
            setSortBy={setSortBy}
            defaultView={defaultView}
            LIST_VIEW={LIST_VIEW}
          />

          <div className="flex-grow-1 camera-list-wrapper">
            <div className="row mb-4">
              <DisplayControls
                activeColumn={noOfColumns}
                handleResponsiveColumns={handleResponsiveColumns}
                defaultView={defaultView}
                setDefaultView={setDefaultView}
                defaultViewApi={defaultViewApi}
                LIST_VIEW="LIST_VIEW"
                SITE_VIEW="SITE_VIEW"
                onSearch={handleSearch}
                route={route}
                addDeviceRoute={`/${route}/cameras/add-new-device`}
              />
            </div>
            <div className="camera-row row">
              {defaultView === LIST_VIEW ? (
                <>
                  <CameraGrid
                    route={route}
                    deviceList={deviceList}
                    columns={noOfColumns}
                    token={authToken}
                    groupUuid={groupUuid}
                  />
                  <div className="row">
                    <CustomPagination
                      page={currentPage}
                      totalPages={totalPageNo}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </>
              ) : (
                <SiteView
                  latlogArray={addressesLatLog}
                  addressesNames={addressesNames}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
