import React from "react";
import {
  Grid,
  Badge,
  Card,
  CardMedia,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { getLocal } from "../../../../helper/common";
import {
  baseUrl,
  ENTERPRISE_ADMIN,
  MULTI_SITE_ADMIN,
  SINGLE_SITE_ADMIN,
} from "../../../../constants/common";
import NavigationIcon from "../../recordings/components/NavigationIcon";
import { truncateString } from "../../../../helper/utils";
import { formatBadgeContent } from "../../../../helper/utils";

const CameraGrid = ({ route, deviceList, columns = 4, token, groupUuid }) => {
  const columnSpan = 12 / columns;
  const userRole = getLocal("userRole");

  const handleIconClick = (stateKey, newState) => {
    console.log(`Icon ${stateKey} is now ${newState ? "on" : "off"}`);
    const handlerMap = {
      sound: () => console.log("Sound toggled"),
      mic: () => console.log("Mic toggled"),
      shield: () => console.log("Shield toggled"),
      dim: () => console.log("Dim toggled"),
      horn: () => console.log("Horn toggled"),
    };
    if (handlerMap[stateKey]) {
      handlerMap[stateKey](newState);
    }
  };

  return (
    <Grid container spacing={3}>
      {deviceList?.map((device, index) => (
        <Grid
          item
          xs={12}
          sm={12 / (columns > 2 ? 2 : 1)}
          md={columnSpan}
          lg={columnSpan}
          key={index}
        >
          <Card
            className="card-camera"
            sx={{
              backgroundColor: "#1e1e1e",
              color: "#ffffff",
              position: "relative",
            }}
          >
            {device.is_subscribed &&
            device.is_active &&
            device.status === "ok" ? (
              <>
                <Link
                  to={`/${route}/cameras/live-camera/${device.device_uuid}`}
                  style={{ textDecoration: "none" }}
                >
                  <CardMedia
                    component="img"
                    image={
                      groupUuid === ""
                        ? `${baseUrl}api/1/devices/${device.device_uuid}/snapshot_img/?as_attachment=true&authtoken=${token?.authtoken?.token}`
                        : device.live_url
                    }
                    alt={device.name}
                    sx={{
                      height: "auto",
                      width: "100%",
                      objectFit: "cover",
                      transition: "visibility 0s, opacity 0.3s ease",
                      "&:hover": { border: "3px solid rgba(59, 180, 74, 0.5)" },
                    }}
                  />
                </Link>
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0.5rem",
                    zIndex: 5,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                        backgroundColor:
                          device.status === "ok" ? "green" : "red",
                        marginRight: 1,
                      }}
                    />
                    <Typography variant="body2" sx={{ color: "#fff" }}>
                      {truncateString(device.name, 15)}
                    </Typography>
                  </Box>
                  <Box>
                    {[ENTERPRISE_ADMIN, MULTI_SITE_ADMIN, SINGLE_SITE_ADMIN].includes(
                      userRole
                    ) && (
                      <Link
                        to={`/${route}/cameras/details/${device.device_uuid}`}
                        style={{ textDecoration: "none" }}
                      >
                        <IconButton
                          sx={{
                            color: "#ffffff",
                            "&:hover": { color: "rgba(59, 180, 74, 0.7)" },
                          }}
                        >
                          <SettingsIcon />
                        </IconButton>
                      </Link>
                    )}
                    <Link
                      to={`/${route}/cameras/notifications/${device.device_uuid}`}
                      style={{ textDecoration: "none" }}
                    >
                      <IconButton
                        sx={{
                          color:
                            device.recordings_unacknowledged_count > 0
                              ? "red"
                              : "#ffffff",
                          "&:hover": { color: "rgba(59, 180, 74, 0.7)" },
                        }}
                      >
                        <Badge
                          color="error"
                          badgeContent={formatBadgeContent(11)}
                          sx={{
                            "& .MuiBadge-badge": {
                              minWidth: "26px",
                              height: "26px",
                              borderRadius: "50%",
                              padding: "0 6px",
                              fontSize: "0.75rem",
                              lineHeight: "22px",
                            },
                          }}
                        >
                          <NotificationsIcon />
                        </Badge>
                      </IconButton>
                    </Link>
                  </Box>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    zIndex: 10,
                    visibility: "hidden",
                    opacity: 0,
                    transition: "visibility 0s, opacity 0.3s ease",
                  }}
                  className="navigation-container"
                  onClick={(e) => e.stopPropagation()} // Prevent event propagation
                >
                  <NavigationIcon
                    position={{ bottom: 10 }}
                    onIconClick={handleIconClick}
                    iconsToShow={["sound", "mic", "dim", "horn", "shield"]}
                  />
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 0,
                  paddingTop: "56.25%",
                  backgroundColor: "#333333",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0.5rem",
                    zIndex: 5,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                        backgroundColor:
                          device.status === "ok" ? "green" : "red",
                        marginRight: 1,
                      }}
                    />
                    <Typography variant="body2" sx={{ color: "#fff" }}>
                      {truncateString(device.name, 15)}
                    </Typography>
                  </Box>
                  <Box>
                    {[ENTERPRISE_ADMIN, MULTI_SITE_ADMIN, SINGLE_SITE_ADMIN].includes(
                      userRole
                    ) && (
                      <Link
                        to={`/${route}/cameras/details/${device.device_uuid}`}
                        style={{ textDecoration: "none" }}
                      >
                        <IconButton
                          sx={{
                            color: "#ffffff",
                            "&:hover": { color: "rgba(59, 180, 74, 0.7)" },
                          }}
                        >
                          <SettingsIcon />
                        </IconButton>
                      </Link>
                    )}
                    <Link
                      to={`/${route}/cameras/notifications/${device.device_uuid}`}
                      style={{ textDecoration: "none" }}
                    >
                      <IconButton
                        sx={{
                          color:
                            device.recordings_unacknowledged_count > 0
                              ? "red"
                              : "#ffffff",
                          "&:hover": { color: "rgba(59, 180, 74, 0.7)" },
                        }}
                      >
                        <Badge
                          color="error"
                          badgeContent={formatBadgeContent(7)}
                          sx={{
                            "& .MuiBadge-badge": {
                              minWidth: "26px",
                              height: "26px",
                              borderRadius: "50%",
                              padding: "0 6px",
                              fontSize: "0.75rem",
                              lineHeight: "22px",
                            },
                          }}
                        >
                          <NotificationsIcon />
                        </Badge>
                      </IconButton>
                    </Link>
                  </Box>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  
                  <VisibilityOffIcon
                    sx={{ color: "#888888", fontSize: 40, marginRight: 1 }}
                  />
                  <Typography
                    variant="body1"
                    sx={{ fontSize: 12, color: "#888888", whiteSpace: "nowrap", }}
                  >
                    Camera Offline
                  </Typography>
                </Box>
              </Box>
            )}
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CameraGrid;
